<template>
  <nav id="top-menu">
    <button v-if="backBtn" class="btn toggle-btn back-btn" v-on:click="$router.go(-1)">
      <i class="fas fa-chevron-left"></i>
    </button>
    <header>
      <ButtonComponent v-b-toggle.modal-menu class="d-block d-sm-none" is-icon variant="tertiary">
        <span class="material-icons-outlined">menu</span>
      </ButtonComponent>

      <div class="aside-toolbar">
        <ul class="site-logo">
          <li>
            <!-- START LOGO -->
            <a href="index.html">
              <template v-if="!whitelabelReady">
                <div class="placeholder-logo"></div>
              </template>
              <template v-else-if="whitelabelLogo">
                <img :src="whitelabelLogo" class="whitelabel-logo"/>
              </template>
              <template v-else>
                <img alt="Yup Chat"
                     class="whitelabel-logo logo-light-mode"
                     src="../../assets/logo/logo-light-mono-tagline.svg"/>
                <img alt="Yup Chat" class="whitelabel-logo logo-dark-mode"
                     src="../../assets/logo/logo-dark-tagline.svg"/>
              </template>
            </a>
            <!-- END LOGO -->
          </li>
        </ul>
      </div>
      <ul class="navbar-nav nav-left d-none d-sm-block">
        <li class="nav-item nav-text dropdown dropdown-menu-md show">
          <account-select/>
        </li>
      </ul>

      <div class="top_menu_items">
        <div class="d-flex">
          <div class="px-1 align-self-center d-none d-sm-block">
            <NotificationsDropdown></NotificationsDropdown>
          </div>
          <div class="px-1 align-self-center d-none d-sm-block">
            <DropdownComponent no-caret variant="tertiary">
              <template #button>
                <span class="material-symbols-outlined">help</span>
              </template>
              <a class="dropdown-item" href="https://yup.chat/faq" target="_blank">
                FAQ
                <span class="material-icons-outlined">
                  open_in_new
                </span>
              </a>
              <a :href="docUrl" class="dropdown-item" target="_blank">
                API
                <span class="material-icons-outlined">
                  open_in_new
                </span>
              </a>
              <hr class="dropdown-divider" role="separator"/>
              <router-link class="dropdown-item" to="/support">
                {{ $t('generic-str.open-call') }}
              </router-link>
            </DropdownComponent>
          </div>
          <div class="text-right pl-1">
            <DropdownComponent variant="secondary">
              <template #button>
                <div :class="{
                  'bg-success': $live.status === 'AVAILABLE',
                  'bg-warning': $live.status === 'UNAVAILABLE',
                  'bg-danger': $live.status === 'OFFLINE',
                  'bg-info': $live.status === 'BREAK',
                }" class="status-online status mr-1"/>
                <span class="d-none d-sm-inline-block">{{
                    $t(`app.${$live.status.toLowerCase()}`)
                  }} |</span>
                {{ $live.statusTime | roundTime }}
              </template>
              <a :class="{active:$live.status === 'AVAILABLE'}" class="dropdown-item"
                 href="javascript:void(0)"
                 @click="$live.setStatus('AVAILABLE')">
                <span class="live-status bg-success"></span>
                Disponível
              </a>
              <a :class="{active:$live.status === 'UNAVAILABLE'}" class="dropdown-item"
                 href="javascript:void(0)"
                 @click="$live.setStatus('UNAVAILABLE')">
                <span class="live-status bg-warning"></span>
                Indisponível
              </a>
              <a :class="{active:$live.status === 'BREAK'}" class="dropdown-item"
                 href="javascript:void(0)"
                 @click="$live.setStatus('BREAK')">
                <!-- <i class="fas fa-pause"></i> -->
                <span class="live-status bg-info"></span>
                Pausa
              </a>
              <a :class="{active:$live.status === 'OFFLINE'}" class="dropdown-item"
                 href="javascript:void(0)"
                 @click="$live.setStatus('OFFLINE')">
                <!-- <i class="fas fa-minus-circle text-danger"></i> -->
                <span class="live-status bg-danger"></span>
                Offline
              </a>
            </DropdownComponent>
          </div>
          <div class="text-right pl-1">
            <DropdownComponent variant="link">
              <template #button>
                <AvatarComponent :image="user.profile_picture" :name="user.name"/>
              </template>
              <router-link class="dropdown-item" to="/accounts">
                <span class="material-icons-outlined"> person_outline </span>
                {{ user.name }}
              </router-link>
              <hr class="dropdown-divider" role="separator"/>
              <div class="dropdown-item">
                <span>{{ $t('home.basic-info.limit') }}:</span>
                <div class="w-100"></div>
                <router-link :class="{
                    btn: true,
                    'stretched-link': true,
                    'btn-danger': account.balance <= 0,
                    'btn-warning':
                      account.balance > 0 && account.balance < 10,
                  }" to="/billing" type="button">
                  {{ account.balance | currency }}
                </router-link>
              </div>
              <router-link class="dropdown-item" to="/user/settings">
                <span class="material-icons-outlined"> settings </span>
                {{ $t('generic-str.menu.config') }}
              </router-link>
              <router-link :class="{
                  badge: true,
                  'badge-balance': true,
                  'bg-primary': true,
                  'bg-danger': account.balance <= 0,
                  'bg-warning': account.balance > 0 && account.balance < 10,
                }" to="/billing">
                {{ account.balance | currency }}
              </router-link>
              <a class="dropdown-item" href="javascript:void(0)" @click="logout">
                <span class="material-icons-outlined"> logout </span>
                Logout
              </a>
            </DropdownComponent>
          </div>
        </div>
      </div>
    </header>
  </nav>
</template>

<script>
import AccountSelect from '@/components/rebranding/menu/AccountSelect.vue';
import AvatarComponent from '@/components/ui/AvatarComponent.vue';
import DropdownComponent from '@/components/DropdownComponent.vue';
import NotificationsDropdown from '@/components/menu/NotificationsDropdown.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';

const audio = new Audio('/sounds/alert.mp3');

export default {
  name: 'Toolbar',
  components: {
    ButtonComponent,
    NotificationsDropdown,
    AvatarComponent,
    AccountSelect,
    DropdownComponent,
  },
  props: {
    backBtn: Boolean,
  },
  filters: {
    roundTime: (value) => {
      if (!value) return '--:--';

      const hours = Math.floor(value / 3600);
      const minutes = Math.floor((value % 3600) / 60);
      const secs = Math.floor(value % 60);

      const pad = (num) => num.toString().padStart(2, '0');

      let result = '';

      if (hours > 0) {
        result += `${hours}h ${pad(minutes)}m`;
      } else if (minutes > 0) {
        result += `${minutes}:${pad(secs)}`;
      } else {
        result += `${secs}s`;
      }

      return result;
    },
  },
  computed: {
    account() {
      return this.$store?.state?.account;
    },
    user() {
      return this.$store.state.auth.user;
    },
    name() {
      return this.$store?.state?.auth?.user?.name;
    },
    email() {
      return this.$store?.state?.auth?.user?.email;
    },
    menu() {
      return this.$route.path.split('/')[1];
    },
    whitelabelReady() {
      return !!(this.$store?.state?.admin?.whitelabel?.id);
    },
    whitelabelLogo() {
      return this.$store?.state?.admin?.whitelabel?.logo ?? null;
    },
    docUrl() {
      let url = 'https://doc.yup.chat/';
      if (this.$store.state.admin.whitelabel.name === 'Algar') {
        url = 'https://doc.smsycorporativo.algartelecom.com.br';
      } else if (this.$store.state.admin.whitelabel.name === 'Mercafacil') {
        url = '';
      } else if (this.$store.state.admin.whitelabel.name === 'Kankei') {
        url = 'https://doc.kankei.com.br/';
      }
      return url;
    },
  },
  data() {
    return {
      hasNotification: false,
    };
  },
  created() {
    this.$root.$on('notify:new', (notification) => {
      let type = notification.type.toLowerCase();
      switch (type) {
        case 'alert':
          type = 'danger';
          break;
        case 'text':
          type = 'info';
          break;
        default:
          break;
      }
      this.$toast.show({
        title: notification.title,
        content: notification.content,
        type,
      });
      audio.play();
      this.hasNotification = true;
    });
    this.$notification.setup(this);
  },
  beforeDestroy() {
    this.$root.$off('notify:new', this.fn);
  },
  mounted() {
    function checkOverflow(el) {
      const curOverflow = el.style.overflow;

      if (!curOverflow || curOverflow === 'visible') {
        el.style.overflow = 'hidden';
      }

      const isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

      el.style.overflow = curOverflow;

      return isOverflowing;
    }
  },
  methods: {
    checkLive() {
      this.$live.setup(this);
    },
    async logout() {
      await this.$live.disconnect();

      this.$store.dispatch('auth/logout').then(async () => {
        this.$router.push('/login');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables';

#top-menu {
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  min-width: 320px;
  border-bottom: 1px solid #d6dadd;
  background: var(--background-2);
  height: 62px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);

  .btn-balance {
    border: none;
    border-radius: 1em;
    font-size: 0.8rem;
    display: none;
    width: fit-content;

    @media (min-width: 660px) {
      display: flex;
      align-items: center;
    }
  }

  .badge-balance {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #fff;
    display: none;

    @media (max-width: 659px) {
      display: block;
      background: #fc0;
    }
  }
}

#top-menu header {
  padding: 4px 0;
  display: flex;
  align-items: center;
  width: 100%;
  @media (min-width: 576px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

#top-menu .toggle-btn {
  float: left;
  border: none !important;
  background: transparent center center no-repeat !important;
  color: transparent !important;
  border: none !important;
  border-right: 1px solid #d6dadd !important;
  width: 62px !important;
  height: 62px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

#top-menu .logo {
  background: url(../../assets/logo.svg) no-repeat;
  background-size: contain;
  width: 168px;
  height: 30px;
  float: left;
  margin-top: 0.7em;
  margin-left: 1.5em;
  position: relative;
}

#top-menu > header {

  .placeholder-logo,
  .whitelabel-logo {
    width: 120px;
    height: 30px;
    float: left;
    position: relative;
  }
}

#top-menu .dropdown::v-deep {
  border-radius: 100%;
  position: relative !important;
  color: #0f0f0f !important;
  border: none !important;

  &.profile {
    height: 2.5em !important;
    width: 2.5em !important;
  }
}

#top-menu::v-deep .dropdown > button:hover {
  filter: saturate(0.7);
}

#top-menu::v-deep .dropdown > button i {
  margin: 0 !important;
  color: inherit !important;
}

#top-menu::v-deep .dropdown > button::after {
  display: none;
}

#top-menu::v-deep {
  .live-status {
    border-radius: 12px;
    vertical-align: -2px;
    display: inline-block;
    height: 12px;
    width: 12px;
    margin-right: 5px;
  }

  .live-break {
    min-width: 42px;
    text-align: center;
    font-size: 8pt;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    color: #fff;
    padding: 0 4px;
    position: absolute;
    top: -5px;
    right: 3px;
  }
}

#top-menu::v-deep .dropdown-menu {
  transform: none !important;
}

#top-menu::v-deep .notifications > button {
  background: transparent;
  color: #d6dadd;
  border: none;
}

#top-menu::v-deep .notifications form {
  padding: 0;
}

#top-menu::v-deep .notifications form h4 {
  font-weight: bold;
  font-size: 110%;
  padding: 0.9em;
  background: #fff;
  margin: 0;
  border-bottom: 1px solid #d6dadd;
}

#top-menu::v-deep .notifications form aside {
  border: none !important;
  border-bottom: 1px solid #fcfcfc;
  max-height: 320px;
  overflow: auto;
}

.top_menu_items {
  margin-left: auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  &::v-deep {
    .dropdown .dropdown-menu {
      top: 40px !important;
    }
  }
}

@media (max-width: 420px) {
  #top-menu .logo {
    margin-top: 0.85em;
    width: 110px;
  }

  #top-menu .notification-content {
    position: fixed !important;
    left: 1% !important;
    transform: translate(0, 50px) !important;
    height: auto !important;
    width: 98% !important;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.3) !important;
  }

  #top-menu .notification-content aside {
    max-height: 70vh !important;
  }
}

.text-right {
  text-align: right !important;
}

.notification-icon {
  color: #fff !important;
  font-size: 20px;
}

.notification-link {
  background: var(--clr-yup-purple) !important;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding-top: 4px;
  display: block;
}

.notification-link:hover {
  background: #9c60f9 !important;
}

.bg-danger {
  background-color: #ff5c75 !important;
}

.status-online {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  display: inline-block;
}

.site-logo {
  width: 5rem;
  padding: 0;
  margin: 0;
  display: inline-block;
  float: left;
  list-style: none;
  @media (min-width: 576px) {
    width: 15rem;
  }
}
</style>
